/* light */
@font-face {
	font-family: 'Menco';
	src: local('Menco'), url(./fonts/Menco/Menco-Light.otf) format('opentype');
	font-weight: 300;
}

@font-face {
	font-family: 'Menco';
	src: local('Menco'), url(./fonts/Menco/Menco-LightItalic.otf) format('opentype');
	font-weight: 300;
	font-style: italic;
}

/* medium */
@font-face {
	font-family: 'Menco';
	src: local('Menco'), url(./fonts/Menco/Menco-Medium.otf) format('opentype');
}

@font-face {
	font-family: 'Menco';
	src: local('Menco'), url(./fonts/Menco/Menco-MediumItalic.otf) format('opentype');
	font-style: italic;
}

/* bold */
@font-face {
	font-family: 'Menco';
	src: local('Menco'), url(./fonts/Menco/Menco-Bold.otf) format('opentype');
	font-weight: 700;
}

@font-face {
	font-family: 'Menco';
	src: local('Menco'), url(./fonts/Menco/Menco-BoldItalic.otf) format('opentype');
	font-weight: 700;
	font-style: italic;
}

/* black */
@font-face {
	font-family: 'Menco';
	src: local('Menco'), url(./fonts/Menco/Menco-Black.otf) format('opentype');
	font-weight: 900;
}

@font-face {
	font-family: 'Menco';
	src: local('Menco'), url(./fonts/Menco/Menco-BlackItalic.otf) format('opentype');
	font-weight: 900;
	font-style: italic;
}


html {
	width: 100%;
	height: 100%;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-family: "Menco", "Helvetica", "Arial", sans-serif;
	overflow-y: auto;
}

*, *:before, *:after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}

* {
	margin: 0;
	padding: 0;

	border: none;
	text-decoration: none;
	outline: none;

	-webkit-tap-highlight-color: rgba(0,0,0,0);
	/*  -webkit-user-select: none;  
	-moz-user-select: none;     
	-ms-user-select: none;      
	user-select: none; */
	border-radius: 0px;
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
}

html * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	font-size: 16px;
	font-family: "Menco", "Helvetica", "Arial", sans-serif;
	color: #fff;
	background-color: #212327;
	width: 100%;
	height: 100%;
}

html, body {
	/* prevent horizontal scrolling */
	/*  overflow-x: hidden;

	overflow-y: auto;*/
	-webkit-overflow-scrolling: touch;
}

a {
	color:inherit;
	outline:none;
	text-decoration:none;
	cursor:pointer;
}
a img {outline : none;}
ul {list-style: none;}
select {outline:none;}
h1,h2,h3,h4,h5,h6 {
    margin: 0;
    padding: 0;
}

.no-select{
	-webkit-user-select: none;  
	-moz-user-select: none;     
	-ms-user-select: none;      
	user-select: none;
}

.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.center-wrapper {
	display: table;
	width: 100%;
	height: 100%;
}
.center-wrapper .center {
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

#root{
	height: 100%;
}

/* .wrapper{
	position: relative;
	float: left;
	width: 100%;
}

.wrapper .container{
	position: relative;
	max-width: 1440px;
	height: 100%;
	margin: 0 auto;
}

.wrapper .container-fixed{
	position: relative;
	width: 1440px;
	height: 100%;
	margin: 0 auto;
} */

/* @media (max-width: 1440px){ 
	.wrapper .container-fixed{
		overflow: hidden;
	}
}

.fixed-size{
  width: 1440px;
}

@media (min-width: 1440px){ 
  	.fixed-size{
    	width: 100%;
  	}
} */

