.root{
	display: inline-block;
	height: 340px;
	width: 300px;
	vertical-align: top;
	color: rgba(255, 255, 255, 0.5);
}

.even{
	background-color: rgba(39, 41, 45, 0.5);
}

.firstRow{
	line-height: 60px;
	height: 60px;
	text-align: center;
}

.firstRow img{
	vertical-align: middle;
	margin-right: 12px;
}

.firstRow .name{
	vertical-align: middle;
	text-transform: capitalize;
}


.row{
	line-height: 40px;
	height: 40px;
	text-align: center;
}

.lastRow{
	color: rgba(113, 200, 236, 0.75);
}

.coinAvailable{
	color: rgba(255, 255, 255, 0.75);
	margin-left: 5px;
	margin-right: 5px;
}

.usdAvailable{
	color: rgba(113, 200, 236, 0.75);
	margin-left: 5px;
	margin-right: 5px;
}

.wdFee{
	color: rgba(231, 161, 92, 0.75);
	margin-left: 5px;
	margin-right: 5px;
}
