/* .root{
	background-color: red;
} */

.overview{
	display: inline-block;
	width: 400px;
	height: 190px;
	background: #27292E;
	margin-right: 20px;
}

.graph{
	display: inline-block;
	width: 1262px;
	height: 190px;
	background: #27292E;
}

.exchangeStats{
	position: relative;
	margin-top: 50px;
}

.exchangeStats .coinsTable{
	display: inline-block;
	height: 340px;
	width: 70px;
	vertical-align: top;
	color: rgba(255, 255, 255, 0.5);
	text-align: left;
}

.exchangeStats .coinsTable .firstRow{
	line-height: 60px;
	height: 60px;
}

.exchangeStats .coinsTable .row{
	line-height: 40px;
	height: 40px;
}

.exchangeStats .totalTable{
	display: inline-block;
	height: 340px;
	width: 112px;
	vertical-align: top;
	color: rgba(255, 255, 255, 0.5);
	text-align: right;
	padding-right: 20px;
}

.exchangeStats .totalTable .firstRow{
	line-height: 60px;
	height: 60px;
}

.exchangeStats .totalTable .row{
	line-height: 40px;
	height: 40px;
}

.exchangeStats .totalTable .lastRow{
	color: rgba(113, 200, 236, 0.75);
}

.exchangeStats .dividerTop{
	position: absolute;
	top: 60px;
	height: 1px;
	width: 100%;
	background-color: #3E3E3E;
}

.exchangeStats .dividerBottom{
	position: absolute;
	bottom: 40px;
	height: 1px;
	width: 100%;
	background-color: #3E3E3E;
}