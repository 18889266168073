.root{
	height: 100%;
	width: 1832px;
}

.left{
	display: inline-block;
	width: 70px;
	height: 100%;
	vertical-align: top;
}

.right{
	display: inline-block;
	height: 100%;
	vertical-align: top;
	padding-left: 40px;
	padding-top: 40px;
}
